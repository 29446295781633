:root {
  --primary-black: 38, 38, 38;
  --border-color-table: #e4e5e9;
  --table-header-background: #fff;
  --table-even-row: #fafafa;
  --sidebar-border: #e8e8e8;
  --sidebar-background: #fafafa;
  --primary-color: 39, 86, 179;
  --table-row-hover: 38, 38, 38;
}

.k-var--accent,
.k-var--primary,
.k-drop-hint-line {
  background-color: #ff6358;
}
/*  */
aside {
  background: var(--sidebar-background);
  color: var(--primary-black);
}
/* nav {
  background: var(--sidebar-background);
  color: white;
} */
/*  */

/* OVERWRITE */
.k-pager-numbers .k-link {
  color: rgba(var(--primary-black)) !important;
}
.k-pager-numbers .k-link.k-selected,
.k-pager-numbers .k-link.k-selected:hover {
  background: rgba(var(--primary-black), 0.5);
  border-radius: 50% !important;
  color: white !important;
}
.k-pager-numbers .k-link:hover {
  background-color: rgba(var(--primary-black), 0.1);
  border-radius: 50% !important;
  color: #000 !important;
}
.k-pager-info.k-label {
  font-size: 14px !important;
}
.k-grid {
  border-radius: 10px;
  overflow: hidden;
  border-color: var(--border-color-table) !important;
}
.k-grid-header,
.k-header,
th.k-header,
.k-grid-header-wrap,
.k-grouping-header,
.k-grouping-header .k-group-indicator,
.k-grid td,
.k-grid-footer,
.k-grid-footer-wrap,
.k-grid-content-locked,
.k-grid-footer-locked,
.k-grid-header-locked,
.k-filter-row > td,
.k-filter-row > th {
  white-space: nowrap;
  border-color: var(--border-color-table) !important;
}
tbody td {
  border-top: 0.5px solid !important;
  border-bottom: 0.5px solid !important;
}
.k-grid-header {
  background: var(--table-header-background);
}
.k-column-title {
  white-space: nowrap;
}

.k-grid tr.k-alt {
  background: none !important;
}
.k-grid tbody tr:hover,
.k-grid tbody tr.k-alt:hover {
  background: rgba(var(--table-row-hover), 0.05) !important;
}

.k-panelbar > .k-item > .k-link,
.k-panelbar > .k-panelbar-header > .k-link {
  color: rgba(var(--primary-black));
}

.k-panelbar > .k-item > .k-link.k-selected,
.k-panelbar > .k-panelbar-header > .k-link.k-selected,
.k-panelbar > .k-item > .k-link.k-selected:hover,
.k-panelbar > .k-item > .k-link.k-selected.k-hover,
.k-panelbar > .k-panelbar-header > .k-link.k-selected:hover,
.k-panelbar > .k-panelbar-header > .k-link.k-selected.k-hover {
  background-color: rgba(var(--primary-black), 0.1);
  color: black;
}

.k-pager.k-widget.k-grid-pager {
  background: var(--table-header-background);
}
.k-filtercell {
  gap: 5px;
}

.k-button.k-button-solid-primary {
  background: rgb(var(--primary-color)) !important;
  border: none !important;
}
.k-dropdownlist.k-picker.k-picker-md.k-rounded-md.k-picker-solid,
.k-button.k-button-md.k-button-solid.k-button-solid-base.k-rounded-md.k-icon-button.k-disabled {
  background: transparent;
}

.k-list .k-selected {
  background: rgba(var(--primary-color)) !important;
}
.k-panelbar .k-panelbar-content {
  padding: 20px !important;
}

.k-grid tbody tr.selected-row:hover,
.k-grid tbody tr.k-alt.selected-row:hover,
.k-master-row.selected-row,
.k-master-row.k-alt.selected-row {
  background-color: rgba(var(--primary-color), 0.8) !important;
  color: white !important;
}

.trip-details-panel .k-animation-container.k-animation-container-relative {
  z-index: 1 !important;
}

.k-radio:checked, .k-radio.k-checked{
  border-color:rgba(var(--primary-color)) !important;
  background-color: rgba(var(--primary-color)) !important;
}