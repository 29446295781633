@font-face {
  font-family: "Inter";
  src: url("./styles/fonts/Inter.ttf") format("truetype");
}
* {
  box-sizing: border-box;
}
*,
*::before,
*::after {
  outline: none !important;
}
*:focus {
  outline: none;
}

body {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
}

@media (min-width: 480px) {
  h1 {
    font-size: 1.5rem;
    font-weight: 400;
  }
  h2 {
    font-size: 1.25rem;
    font-weight: 200;
  }
}

@media (min-width: 768px) {
  h1 {
    font-size: 1.75rem;
    font-weight: 400;
  }
  h2 {
    font-size: 1.75rem;
    font-weight: 200;
  }
}

span.k-textbox {
  width: 100%;
}

.logo-wrapper {
  height: 300px;
  width: 270px;
}

.logo {
  height: 186px;
  width: 270px;
}
.banner {
  height: 68px;
  color: #ff6358;
  font-weight: 300;
  font-size: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 32px;
}
.account {
  position: relative;
  height: 20px;
  left: 31.5px;
  margin: 32px 0px 32px 0px;
  font-size: 14px;
}

.account > a {
  margin-left: 16px;
  color: #0058e9;
}

.continue-with-wrapper {
  display: inline-flex;
  height: 20px;
  width: 270px;
  margin-bottom: 16px;
  margin-top: 16px;
}
.continue-with-wrapper > span {
  position: static;
  color: #8f8f8f;
  font-size: 14px;
  line-height: 20px;
}

hr {
  width: 75px;
  height: 0px;
  border: 1px solid #8f8f8f;
  margin-top: 9px;
}

.sign-in-page {
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.sign-in-main-container {
  margin: 20px auto;
  max-width: 1200px;
  padding: 20px;
  overflow: hidden;
}
.sign-in-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.sign-in-image-col {
  padding: 0 20px;
  max-width: 100%;
  max-height: 100%;
  border-right: 1px solid #000;
}

.sign-in-form {
  background-color: #fff;
}

.autopilot-image {
  max-width: 100%;
  max-height: 700px;
  width: auto;
  height: auto;
}

.sign-in-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  min-width: 560px;
  width: 39%;
  z-index: 1;
  background: #ffffff;
}

.login {
  height: 202px;
  width: 270px;
  justify-content: space-between;
  display: flex;
  flex-flow: column;
}
/* div.k-form-field:nth-child(3) {
  display: flex;
} */
/* .edit-bank-details .k-form-field:nth-child(3) {
  display: block;
} */
.RememberMe-form-label {
  order: 1;
  padding-left: 0.5em;
}
.k-form-field-wrap {
  position: relative;
}

.sign-button {
  height: 30px;
  width: 270px;
  background: #ff6358;
  color: #ffffff;
}

.sign-button:hover {
  background: #ff6358;
}

.k-button-text {
  text-decoration: none;
}

.social-wrapper {
  display: flex;
  width: 194px;
  height: 30px;
  top: 755px;
  left: 183px;
}

.facebook {
  height: 30px;
  width: 30px;
  left: 20px;
  top: 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 15px;
  background: rgba(66, 66, 66, 0.04);
  border-radius: 2px;
}

.twitter {
  height: 30px;
  width: 30px;
  left: 20px;
  top: 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 35px;
  background: rgba(66, 66, 66, 0.04);
  border-radius: 2px;
}

.reddit {
  height: 30px;
  width: 30px;
  left: 20px;
  top: 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 35px;
  background: rgba(66, 66, 66, 0.04);
  border-radius: 2px;
}
.image-wrapper {
  position: absolute;
  right: 0;
  bottom: 0;
  overflow: hidden;
  width: 61%;
  height: 60%;
  z-index: 9000;
}

.second-image-wrapper {
  position: absolute;
  right: 0;
  bottom: 0;
  overflow: hidden;
  width: 61%;
  height: 60%;
}

.image-wrapper img {
  z-index: 9000;
  margin: auto;
  display: block;
}

.frame-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: 61%;
  max-width: 542px;
  margin: 0 auto;
}

.frame-wrapper h2 {
  font-size: 48px;
  color: #ffffff;
  font-weight: bold;
  text-align: center;
  line-height: 1.4;
}

.frame-wrapper h4 {
  font-size: 19px;
  text-align: center;
  color: #ffffff;
  font-weight: normal;
  line-height: 1.3;
}

.text-wrapper {
  margin-top: 5vh;
}

.dashboard-button {
  text-decoration: none;
}

.custom-toolbar {
  padding: 6px 25px 0px 24px;
  height: 48px;
  background-color: #ffffff;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.06);
  height: 40px;

  button {
    border-color: transparent;
    background-color: white;
    border: 0px;
  }

  button:hover {
    border-color: transparent;
    background-color: white;
    border: 0px;
  }
}

.overview-button {
  height: 30px;
  width: 30px;
  color: #2d73f5;
}

.drawer {
  border-right-width: 1px;
  height: 100%;
}

.overview {
  color: #2d73f5;
  margin-left: 16px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
}

.right-widget {
  float: right;
  display: flex;
}

.alert-container {
  margin-right: 50px;
  a {
    color: #424242 !important;
  }
}

.k-avatar {
  height: 64px;
  width: 64px;
}

.p-2 {
  width: 398px;
}

.message-container {
  margin-top: 16px;
  margin-left: 8px;

  h1,
  h2 {
    width: 300px;
  }
}

.popup-content {
  margin-top: 10px;
}

.mark-button {
  height: 30px;
  width: 165px;
  float: right;
  border: solid 1px #ff6358;
  color: #ff6358;
  background-color: white;
  right: 10px;
}

.message-container:nth-child(1) {
  background-color: lightyellow;
}

.k-drawer-content {
  margin-top: -212px;
  height: 100%;
  width: 100%;
  background-color: #fafafa;
  max-width: calc(100% - 240px);
}

.user-container {
  width: 240px;
  padding-top: 32px;
  max-width: 100%;
  border-width: 0;
  border-right: solid 1px rgba(0, 0, 0, 0.08);

  box-sizing: border-box;
  outline: 0;
  text-align: center;
  img {
    height: 64px;
    width: 64px;
  }

  h1 {
    font-size: 14px;
    margin-top: 13px;
    color: #424242;
    font-family: "Inter";
    font-weight: 700;
  }

  .user-conatiner .user-email {
    margin-top: 10px !important;
    font-size: 8px;
    margin-top: 12px;
    text-decoration: underline;
    font-weight: 400;
  }

  .user-button {
    font-size: 12px;
    width: 71px;
    height: 30px;
    margin-top: 11px;
    background: rgba(66, 66, 66, 0.16);
    border-radius: 2px;
    margin: 12px 0 9px 0;
  }
}

a {
  text-decoration: none !important;
}

my-app {
  padding: 0 !important;
}

.k-filtercell-operator {
  display: none;
}

.k-drawer-container {
  width: 100%;
  height: 100%;
  position: relative;
  min-height: 70vh;
}

.k-drawer {
  height: 100%;
  max-width: 100%;
  border-width: 0;
  border-style: solid;
  box-sizing: border-box;
  outline: 0;
  font-family: inherit;
  font-size: 14px;
  line-height: 1.42857143;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
}

.custom-toolbar {
  padding: 6px 25px 0px 24px;
  position: relative;
  z-index: 300;
  height: 48px;
  background: #ffffff;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.06);
  width: 100%;
  height: 40px;
}

.k-notification-container {
  .k-widget {
    padding-top: 0px;
  }
}

.k-popup {
  .k-widget {
    padding-top: 0px;
  }
}

.greeting {
  font-weight: 300;
  font-size: 28px;
  padding: 24px 0px 8px 17px;
  background: #fafafa;
}

.dashboard-card-content-number {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 84px;
  line-height: 80px;
  text-align: center;
}

.dashboard-card-content {
  text-align: center;
}

.green {
  color: #37b400;
}

.red {
  color: #f31700;
}

.orange {
  color: #ffc000;
}

.footer,
.k-card-title {
  color: #666666;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
}

.k-card-header {
  border-color: transparent;
}

.gauge-div {
  margin-top: -60px;
  text-align: center;
}

.gauge-footer {
  color: #666666;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 42px;
}

.card-buttons {
  display: flex;
  justify-content: space-between;

  margin-top: 20px;
  margin-right: 34px;
  Button {
    width: 106px;
    height: 30px;
  }

  p {
    margin-left: 15px;
    font-size: 20px;
  }

  .k-daterange-picker,
  .k-daterangepicker {
    margin-top: -20px;

    label {
      display: none;
    }
  }
}

.chart-container {
  margin-left: 13px;
  margin-right: 13px;
}

.k-rating-item.k-selected {
  color: #ffa600;
}

.person-name {
  margin-left: 10px;
}

.grid-container {
  margin-top: 0px;
  height: 500px;
  .export-buttons-container {
    margin-left: 10px;
  }
}

.circular-gauge-container {
  display: flex;
}

.values-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}

.progress-bar-container {
  display: flex;
  padding-bottom: 10px;
  color: black;
  font-weight: 700;
  margin-bottom: -20px;
  font-size: 12px;
  span {
    padding: 5px;
  }
}

.value-span {
  margin-top: -7px;
  margin-left: 21.25px;
}

.country-name-container {
  margin-top: -7px;
  margin-right: 11px;
}

.country-name {
  font-size: 12px;
  width: 250px;
}

.listbox-card-container {
  margin-top: 100px;
  .k-listview-content {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }
  .k-icon.k-i-preview {
    z-index: 10;
    bottom: 0px;
    position: inherit;
    right: -1px;
    width: 24px;
    height: 24px;
    color: gray;
  }
}

.input-container {
  text-align: center;
  margin-top: 50px;
  input {
    width: 500px;
  }
}

.footer-buttons-container {
  display: flex;
  flex-direction: column;
  margin-left: 75px;
  margin-top: -7px;
}

.left {
  float: left;
}

.right {
  float: right;
}

.right div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100px;
}

.right h6 {
  color: red;
}

.bottom-side {
  margin-top: 90px;
}

.top-side {
  margin-top: 820px;
}

.switch-parent,
.f-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 70px;
}

.date-container {
  text-align: center;
}

.weather-data {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  width: 100%;
  text-align: center;
}

.weather-data-container {
  margin-top: 16px;
  padding: 8px 0px 0px;
  gap: 18px;
}

.icon-header-container {
  display: flex;
  justify-content: space-between;
  p {
    color: #666666;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
  }

  a {
    font-size: 20px;
    color: #0058e9 !important;
  }

  .globe-span {
    padding-right: 15px;
    margin-top: -4px;
  }
}

.triple-inputs {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.switch-options {
  .switch-parent {
    margin-top: 0px;
    height: 10px;
  }
}

/* .k-disabled {
   display: none;
} */

.card-buttons-parent {
  display: flex;
  justify-content: end;
  margin-top: -60px;
  margin-right: 0px;
}

.gray-text {
  color: #8f8f8f;
}

.about {
  margin-left: 35px;
  margin-top: 35px;
  margin-bottom: 35px;
}

.overflow {
  overflow-y: scroll;
}

@media (max-width: 800px) {
  .image-wrapper {
    display: none;
  }
}

.password-icon {
  position: absolute;
  margin-left: 245px;
  margin-top: 28px;
  color: gray;
}

.switch {
  margin-left: 20px;
}

.form-side-url {
  margin-top: 6%;
}

.cursor-pointer {
  cursor: pointer;
}

.text-align-center {
  text-align: center;
}

.document-link-url {
  color: #007bff;
  cursor: pointer;
}

/* //Table Header Word Wrap */
.k-grid-header .k-header,
.k-column-title {
  text-overflow: inherit;
  white-space: inherit;
  vertical-align: inherit;
}

.k-grid-table .k-master-row .k-button {
  margin-top: 5px !important;
  margin-left: 5px;
}

.k-i-arrow-left,
.driver-profiles-table .k-master-row,
.driver-accounts-table .k-master-row,
.trip-management-table .k-master-row,
.pricing-change-logs-table .k-master-row {
  cursor: pointer;
}

.k-i-arrow-left {
  font-size: 24px;
}

.trip-details-tab {
  padding: 20px 20px 0px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  margin-bottom: 20px;
}

.trip-details-tab h6 {
  color: #ff6358;
}

.trip-details-header .flag-col {
  text-align: right;
}

.trip-details-header .flag-div {
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: end;
}

.trip-details-header .trip-critical-zone-div {
  padding: 10px 20px;
  background-color: #f31700;
  color: #fff;
  font-weight: 500;
}

.trip-details-header .trip-stage-div {
  padding: 10px 20px;
  background-color: #ff6358;
  color: #fff;
}

.trip-details-div-alignment {
  display: flex;
  justify-content: space-between;
  gap: 40px;
}

.trip-details-div-alignment span {
  min-width: 50px;
}

.allocation-config .k-form .k-form-field,
.pricing-config .k-form .k-form-field,
.waivers-tab .k-form .k-form-field,
.refund-form-div .k-form .k-form-field {
  margin-top: 0px;
}

.coupon-homepage .k-form-field .k-label,
.customer-management-home .k-form-field .k-label {
  display: none !important;
}

.pricing-config .k-form .k-form-field {
  width: 100px;
}

.pricing-config .night-charges-row .k-form-field {
  width: 200px;
}

.pricing-config .weekend-charges-row .k-form-field {
  width: 140px;
  margin-left: 12px;
}

.pricing-config .rate-card .k-dropdownlist {
  width: 150px;
}

.trip-details-tab .row {
  /* // margin-top: 20px; */
  margin-bottom: 20px;
}

.pricing-details .row .col-sm-3,
.pricing-details .row .col-sm-4 {
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: 5px;
  margin-right: 5px;
  font-weight: bold;
}

.grey-background {
  background-color: #f6f6f6;
}

.dflex {
  display: flex;
}

.flex-basic-prop {
  display: flex;
  justify-content: center;
  align-items: center;
}

.jcsp {
  justify-content: space-between;
}

.txtcenter {
  text-align: center;
}

.txtright {
  text-align: right;
}

.alc {
  align-items: center;
}

.loading-div {
  min-height: 100px;
}

.k-animation-container-relative {
  display: block;
}

.driver-account p {
  font-size: 15px;
}

.k-calendar-navigation {
  display: none !important;
}

.upload-driver-contract-div {
  padding: 10px;
  background-color: white;
  display: inline-flex;
  width: 60%;
  justify-content: space-between;
}

#dashboard-superset iframe {
  width: 100%;
  height: 700px;
  border: 0;
  overflow: hidden;
}

.customer-details .col-md-6 {
  word-break: break-all;
}

.driver-online-activity-details {
  padding-top: 20px;
  border-radius: 4px;
  border: 1px solid #ebebeb;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.driver-online-activity-details .driver-details-container {
  width: 90%;
  text-align: center;
}

.driver-online-activity-details .driver-details-container img {
  width: 20%;
  border-radius: 50%;
}

.driver-online-activity-details .driver-stats {
  display: flex;
  padding: 16px;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #ebebeb;
  width: 100%;
}

.driver-online-activity-details .calendar {
  margin-top: 15px;
  padding: 15px;
  width: 100%;
}
.k-loading-mask {
  z-index: 10;
}
/* // .driver-activity-table .k-filter-row th:nth-child(4) .k-filtercell,
// .driver-activity-table .k-filter-row th:nth-child(5) .k-filtercell{
//   display: none;
// } */

/*  */
.pricing-config .k-animation-container.k-animation-container-relative {
  z-index: 1 !important;
}
.pricing-config .k-numerictextbox input {
  text-align: center;
}
.pricing-config .k-input-spinner.k-spin-button {
  display: none;
}
/* 
.k-animation-container.k-animation-container-relative {
  z-index: 1 !important;
} */

.app-cover {
  background: url("./assets/cover.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
