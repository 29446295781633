@tailwind base;
@tailwind components;
@tailwind utilities;

/* .logout-btn {
  background: transparent;
  border: 1px solid #c90000;
  font-size: 14px;
  padding: 4px 10px;
  color: #c90000;
  border-radius: 4px;
  transition-duration: 300ms;
}
.logout-btn:hover {
  background: #c90000;
  color: white;
} */

*:focus {
  box-shadow: none !important;
}

*::placeholder {
  opacity: 0.5 !important;
}

*::-ms-input-placeholder {
  opacity: 0.5 !important;
}

[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  appearance: none;
  height: 10px;
  width: 10px;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE2LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCINCgkgd2lkdGg9IjEyMy4wNXB4IiBoZWlnaHQ9IjEyMy4wNXB4IiB2aWV3Qm94PSIwIDAgMTIzLjA1IDEyMy4wNSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTIzLjA1IDEyMy4wNTsiDQoJIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPGc+DQoJPHBhdGggZD0iTTEyMS4zMjUsMTAuOTI1bC04LjUtOC4zOTljLTIuMy0yLjMtNi4xLTIuMy04LjUsMGwtNDIuNCw0Mi4zOTlMMTguNzI2LDEuNzI2Yy0yLjMwMS0yLjMwMS02LjEwMS0yLjMwMS04LjUsMGwtOC41LDguNQ0KCQljLTIuMzAxLDIuMy0yLjMwMSw2LjEsMCw4LjVsNDMuMSw0My4xbC00Mi4zLDQyLjVjLTIuMywyLjMtMi4zLDYuMSwwLDguNWw4LjUsOC41YzIuMywyLjMsNi4xLDIuMyw4LjUsMGw0Mi4zOTktNDIuNGw0Mi40LDQyLjQNCgkJYzIuMywyLjMsNi4xLDIuMyw4LjUsMGw4LjUtOC41YzIuMy0yLjMsMi4zLTYuMSwwLTguNWwtNDIuNS00Mi40bDQyLjQtNDIuMzk5QzEyMy42MjUsMTcuMTI1LDEyMy42MjUsMTMuMzI1LDEyMS4zMjUsMTAuOTI1eiIvPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPC9zdmc+DQo=);
  background-size: 10px 10px;
}

/* Driver status tags */
.status {
  padding: 2px 8px;
  border-radius: 4px;
  text-transform: capitalize;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.52px;
  text-align: left;
  display: inline-block;
  text-wrap: nowrap;
  max-width: fit-content;
}

.online {
  background: #f2fdf5;
  color: #236e2d;
}

.out_of_serviceable_area {
  background: #f3efff;
  color: #76679f;
}

.offline {
  background: #fff6f4;
  color: #c14d3d;
}

.trip_acceptance_timer_running {
  background: #fff9e3;
  color: #9d811f;
}

.in_trip {
  background: #eef6ff;
  color: #243f5f;
}

.check_in_timer_running {
  background: #e7e1f6;
  color: #4300e0;
}

.check_out {
  background: #ffd2d2;
  color: #8b0000;
}

/* FleetMap status styles */
.mapStatus {
  padding: 2px 8px;
  gap: 4px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  box-shadow: 0px 4px 28px 0px #00000029;
  text-transform: capitalize;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.52px;
  text-align: left;
  background: white;
}

.mapStatus.offline {
  border: 1px solid #c14d3d;
  color: #c14d3d;
}
.mapStatus.offline > span {
  background: #c14d3d;
}

.mapStatus.online {
  border: 1px solid #236e2d;
  color: #236e2d;
}
.mapStatus.online > span {
  background: #236e2d;
}

.mapStatus.out_of_serviceable_area {
  border: 1px solid #76679f;
  color: #76679f;
}
.mapStatus.out_of_serviceable_area > span {
  background: #76679f;
}

.mapStatus.trip_acceptance_timer_running {
  border: 1px solid #9d811f;
  color: #9d811f;
}
.mapStatus.trip_acceptance_timer_running > span {
  background: #9d811f;
}

.mapStatus.in_trip {
  border: 1px solid #243f5f;
  color: #243f5f;
}
.mapStatus.in_trip > span {
  background: #243f5f;
}

/* Clicked mapStatus styles */
.clicked .mapStatus.online {
  border: 1px solid #236e2d;
  background: #236e2d;
  color: white;
}
.clicked .mapStatus.online > span {
  background: white;
}

.clicked .mapStatus.offline {
  border: 1px solid #c14d3d;
  background: #c14d3d;
  color: white;
}
.clicked .mapStatus.offline > span {
  background: white;
}

.clicked .mapStatus.out_of_serviceable_area {
  border: 1px solid #76679f;
  background: #76679f;
  color: white;
}
.clicked .mapStatus.out_of_serviceable_area > span {
  background: white;
}

.clicked .mapStatus.trip_acceptance_timer_running {
  border: 1px solid #9d811f;
  background: #9d811f;
  color: white;
}
.clicked .mapStatus.trip_acceptance_timer_running > span {
  background: white;
}

.clicked .mapStatus.in_trip {
  border: 1px solid #243f5f;
  background: #243f5f;
  color: white;
}
.clicked .mapStatus.in_trip > span {
  background: white;
}

/* Top filters */
.topFilter {
  border: none;
  background: transparent;
  border-bottom: 1px solid #cbd5e1;
}

.topFilter.clicked {
  border-bottom: 1.5px solid rgb(var(--primary-color));
}

.custom-border-primary {
  border: 1px solid #cbd5e1;
}

.custom-border-secondary {
  border: 1px solid #00000080;
}

.custom-border-primary-bottom {
  border-bottom: 1px solid #cbd5e1;
}

.pac-container {
  z-index: 100051 !important;
}

/* Custom DatePicker */
.customDatePicker::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

/* Grid columns */
.columns-two {
  @apply tw-grid tw-grid-cols-2;
}
.columns-three {
  @apply tw-grid tw-grid-cols-3;
}
.columns-four {
  @apply tw-grid tw-grid-cols-4;
}
.columns-five {
  @apply tw-grid tw-grid-cols-5;
}

/* Status colors */
.status-pill {
  @apply tw-inline-block tw-px-3 tw-py-1.5 tw-text-xs tw-rounded-full;
}

.INITIATED {
  background-color: #dce8ff;
  color: #254480;
}

.SEARCHING_FOR_DRIVER {
  background-color: #ffe4ce;
  color: #522e13;
}

.DRIVER_ALLOCATED {
  background-color: #e1fffb;
  color: #0a4840;
}

.CHANGE_DRIVER {
  background-color: #ffc4c4;
  color: #791a1a;
}

.IN_PROGRESS {
  background-color: #d5fdd9;
  color: #14561b;
}

.EXPIRED {
  background-color: #fcc593;
  color: #4d4d47;
}

.CANCELLED {
  background-color: #f8c7c0;
  color: #84281b;
}

.REFUNDED {
  background-color: #fff7db;
  color: #50410f;
}

/* 
New check_out and check_in_timer
*/

.mapStatus.check_in_timer_running {
  border: 1px solid #4300e0;
  color: #4300e0;
}
.mapStatus.check_in_timer_running > span {
  background: #4300e0;
}

.clicked .mapStatus.check_in_timer_running {
  border: 1px solid #4300e0;
  background: #4300e0;
  color: white;
}
.clicked .mapStatus.check_in_timer_running > span {
  background: white;
}

.mapStatus.check_out {
  border: 1px solid #8b0000;
  color: #8b0000;
}
.mapStatus.check_out > span {
  background: #8b0000;
}

.clicked .mapStatus.check_out {
  border: 1px solid #8b0000;
  background: #8b0000;
  color: white;
}
.clicked .mapStatus.check_out > span {
  background: white;
}

.tree,
.tree ul,
.tree li {
  position: relative;
}

.tree ul {
  list-style: none;
  padding-left: 0;
  margin-left: 0;
}

.tree ul ul {
  padding-left: 30px;
}

.tree li {
  margin-bottom: 20px;
  position: relative;
}

.tree li::before,
.tree li::after {
  content: "";
  position: absolute;
  left: -20px;
}

.tree ul ul li::before {
  border-top: 2px solid #e4e5e9;
  top: 10px;
  width: 20px;
  height: 0;
}

.tree ul ul li::after {
  border-left: 2px solid #e4e5e9;
  height: calc(100% + 20px);
  width: 0px;
  top: -20px;
}

.tree ul ul > li:last-child::after {
  height: 30px;
}
